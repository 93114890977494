import s from "./Energy.module.scss";

export default function Energy({
  progress,
  energy,
}: {
  progress: string;
  energy: number;
}) {
  const fillBarStyle = {
    width: `${progress}%`,
  };

  return (
    <div className={s.container}>
      <div style={fillBarStyle} className={s.line}></div>
      <span> {energy}</span>
    </div>
  );
}
