import { icons } from "../../icons/icons";
import s from "./Navigation.module.scss";
import { useState } from "react";
import Link from "./Link/Link";
import { useLocation } from "react-router-dom";

export default function Navigation() {
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState(pathname);

  const links = [
    {
      href: "/boost",
      title: "boost",
      slug: "boost",
      img: icons.rocket,
      img_b: icons.rocket_black,
    },
    {
      href: "/leagues",
      title: "leagues",
      slug: "leagues",
      img: icons.leagues,
      img_b: icons.leagues_black,
    },
    {
      href: "/rewards",
      title: "rewards",
      slug: "rewards",
      img: icons.rewards,
      img_b: icons.rewards_black,
    },
    {
      href: "/wallet",
      title: "wallet",
      slug: "wallet",
      img: icons.wallet,
      img_b: icons.wallet_black,
    },
  ];

  const onLinkChange = (href: string) => {
    setActiveLink(href);
  };

  return (
    <nav className={s.navigation}>
      <ul className={s.list}>
        {links.map((l) => (
          <li
            onClick={() => onLinkChange(l.href)}
            key={l.slug}
            className={`${s.list_element} ${
              activeLink === l.href ? s.active : ""
            } `}
          >
            <Link href={`${l.href}`}>
              <span
                className={`${s.link_img} ${
                  activeLink === l.href ? s.active : ""
                }`}
              >
                {activeLink === l.href ? l.img : l.img_b}
              </span>
              <span>{l.title}</span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}
