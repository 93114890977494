import { useState } from "react";
import s from "./Popup.module.scss";

export default function Popup({ closePopup }: { closePopup: () => void }) {
  //   const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={s.popup}>
      {/* <div className={s.cc}>asdasdasdasdasdhasdjhsahsbdjdhasb</div> */}
      <div className={s.close} onClick={closePopup}>
        x
      </div>
      <img src="/popup/bg.png" alt="" />
    </div>
  );
}
