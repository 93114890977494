import s from "./Description.module.scss";

export default function Description({
  title,
  text,
}: {
  title: string;
  text: string;
}) {
  return (
    <div className={s.container}>
      <h2 className={s.title}>{title}</h2>
      <p className={s.text}>{text}</p>
    </div>
  );
}
