import s from "./Reward.module.scss";

export default function Reward({
  prize,
  place,
}: {
  prize: number;
  place: number;
}) {
  return (
    <div className={s.container}>
      <div className={s.place}>{place}.Place</div>
      <div className={s.prize}>${prize}</div>
    </div>
  );
}
