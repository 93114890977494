import { instance } from "api";
import { AxiosResponse } from "axios";
import { ActionType } from "./leagues";

export const usersApi = {
  async getEnergy(userId: number, leagueId: number): Promise<number> {
    const response: AxiosResponse<GetEnergyResponse> =
      await instance.get<GetEnergyResponse>(
        `users/energy?user_id=${userId}&league_id=${leagueId}`
      );
    return response.data.result;
  },
  async updateEnergy(
    user_id: number,
    league_id: number,
    league_season_id: number
  ): Promise<GameValue> {
    const response: AxiosResponse<UpdateGameValueResponse> =
      await instance.put<UpdateGameValueResponse>("users/energy", {
        user_id,
        league_id,
        league_season_id,
      });
    return response.data.result;
  },
  async getCommonScores(userId: number) {
    const response = await instance.get<{ result: number }>(
      `users/common_score?user_id=${userId}`
    );
    return response.data.result;
  },
  async getScores(userId: number) {
    const response: AxiosResponse<GetScoresResponse> =
      await instance.get<GetScoresResponse>(`users/score?user_id=${userId}`);
    return response.data.result;
  },
  async isFirstPlayOnLeague(
    userId: number,
    leagueId: number
  ): Promise<AboutLeagueType[]> {
    const response: AxiosResponse<IsFirstPlayOnLeagueResponse> =
      await instance.get<IsFirstPlayOnLeagueResponse>(
        `users/in_league?user_id=${userId}&league_id=${leagueId}`
      );
    return response.data.result;
  },
  async getCompletedActions(leagueId: number, userId: number) {
    const response = await instance.get<GetCompletedActionsResponse>(
      `actions?league_id=${leagueId}&user_id=${userId}`
    );
    return response.data.result;
  },
  async getRefs(userId: number) {
    const response = await instance.get<{ result: number }>(
      `users/refs?user_id=${userId}`
    );
    return response.data.result;
  },
};

// getEnergy
type GetEnergyResponse = {
  result: number;
};

type UpdateGameValueResponse = {
  result: GameValue;
};

type GameValue = {
  score: number;
  energy: number;
};

// isFirstPlayOnLeague
type IsFirstPlayOnLeagueResponse = {
  result: AboutLeagueType[];
};

export type AboutLeagueType = {
  id: number;
  league_id: number;
  league_season_id: number;
  score: number;
  created_at: string;
  updated_at: string;
  user_id: 393067183;
};

// /getScore

type GetScoresResponse = {
  result: ScoresType[];
};

export type ScoresType = {
  score: number;
  position: number;
  league_id: number;
};

type GetCompletedActionsResponse = {
  result: ActionType[];
};
