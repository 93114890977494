export const icons = {
  leagues: (
    <svg
      width="19.219116"
      height="20.250000"
      viewBox="0 0 19.2191 20.25"
      fill="none"
    >
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        id="leagues_fill"
        d="M18.97 5.24C18.66 4.77 18.11 4.5 17.46 4.5L15.33 4.5L15.64 0.81C15.66 0.6 15.56 0.4 15.42 0.24C15.27 0.08 15.04 0 14.83 0L9.46 0L4.38 0C4.17 0 3.93 0.08 3.79 0.24C3.65 0.4 3.55 0.6 3.57 0.81L3.88 4.5L1.75 4.5C1.1 4.5 0.55 4.77 0.24 5.24C-0.06 5.71 -0.09 6.32 0.18 6.92L1.96 10.93C2.41 11.95 3.64 12.75 4.75 12.75L5.75 12.75C6.63 13.89 7.96 14.69 8.71 14.92L8.71 18.75L5.5 18.75C5.09 18.75 4.75 19.08 4.75 19.5C4.75 19.91 5.09 20.25 5.5 20.25L9.46 20.25L13.71 20.25C14.12 20.25 14.46 19.91 14.46 19.5C14.46 19.08 14.12 18.75 13.71 18.75L10.21 18.75L10.21 14.92C10.96 14.69 12.58 14.25 13.46 12.75L14.46 12.75C15.57 12.75 16.8 11.95 17.25 10.93L19.03 6.92C19.3 6.32 19.27 5.71 18.97 5.24ZM4.75 11.25C4.22 11.25 3.55 10.8 3.33 10.32L1.55 6.31C1.48 6.15 1.49 6.07 1.5 6.06C1.51 6.04 1.58 6 1.75 6L4 6L4.28 9.22C4.38 9.93 4.58 10.62 4.86 11.25L4.75 11.25ZM17.66 6.31L15.88 10.32C15.66 10.8 14.99 11.25 14.46 11.25L14.35 11.25C14.63 10.62 14.83 9.93 14.93 9.22L15.21 6L17.46 6C17.63 6 17.7 6.04 17.71 6.06C17.71 6.07 17.73 6.15 17.66 6.31Z"
        fill="#FFFFFF"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
    </svg>
  ),
  leagues_black: (
    <svg
      width="19.219147"
      height="20.250000"
      viewBox="0 0 19.2191 20.25"
      fill="none"
    >
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        id="leagues_stroke"
        d="M18.97 5.24C18.66 4.77 18.11 4.5 17.46 4.5L15.33 4.5L15.64 0.81C15.66 0.6 15.56 0.4 15.42 0.24C15.27 0.08 15.04 0 14.83 0L9.46 0L4.38 0C4.17 0 3.93 0.08 3.79 0.24C3.65 0.4 3.55 0.6 3.57 0.81L3.88 4.5L1.75 4.5C1.1 4.5 0.55 4.77 0.24 5.24C-0.06 5.71 -0.09 6.32 0.18 6.92L1.96 10.93C2.41 11.95 3.64 12.75 4.75 12.75L5.75 12.75C6.63 13.89 7.96 14.69 8.71 14.92L8.71 18.75L5.5 18.75C5.09 18.75 4.75 19.08 4.75 19.5C4.75 19.91 5.09 20.25 5.5 20.25L9.46 20.25L13.71 20.25C14.12 20.25 14.46 19.91 14.46 19.5C14.46 19.08 14.12 18.75 13.71 18.75L10.21 18.75L10.21 14.92C10.96 14.69 12.58 14.25 13.46 12.75L14.46 12.75C15.57 12.75 16.8 11.95 17.25 10.93L19.03 6.92C19.3 6.32 19.27 5.71 18.97 5.24ZM4.75 11.25C4.22 11.25 3.55 10.8 3.33 10.32L1.55 6.31C1.48 6.15 1.49 6.07 1.5 6.06C1.51 6.04 1.58 6 1.75 6L4 6L4.28 9.22C4.38 9.93 4.58 10.62 4.86 11.25L4.75 11.25ZM13.45 9.04C13.1 11.46 10.96 13.5 9.46 13.5C7.96 13.5 5.97 11.46 5.62 9.04L5.2 1.5L9.46 1.5L14.01 1.5L13.45 9.04ZM17.66 6.31L15.88 10.32C15.66 10.8 14.99 11.25 14.46 11.25L14.35 11.25C14.63 10.62 14.83 9.93 14.93 9.22L15.21 6L17.46 6C17.63 6 17.7 6.04 17.71 6.06C17.71 6.07 17.73 6.15 17.66 6.31Z"
        fill="#2D2D2D"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
    </svg>
  ),
  rewards_black: (
    <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none">
      <desc>Created with Pixso.</desc>
      <defs>
        <clipPath id="clip4_228">
          <rect
            id="Rewards"
            rx="-0.500000"
            width="23.000000"
            height="23.000000"
            transform="translate(0.500000 0.500000)"
            fill="white"
            fillOpacity="0"
          />
        </clipPath>
      </defs>
      <rect
        id="Rewards"
        rx="-0.500000"
        width="23.000000"
        height="23.000000"
        transform="translate(0.500000 0.500000)"
        fill="#FFFFFF"
        fillOpacity="0"
      />
      <g clipPath="url(#clip4_228)">
        <path
          id="Vector"
          d="M12 17C7.58 17 4 13.41 4 9C4 4.58 7.58 1 12 1C16.41 1 20 4.58 20 9C20 13.41 16.41 17 12 17Z"
          stroke="#2D2D2D"
          strokeOpacity="1.000000"
          strokeWidth="1.500000"
          strokeLinejoin="round"
        />
        <path
          id="Vector"
          d="M12 14C9.23 14 7 11.76 7 9C7 6.23 9.23 4 12 4C14.76 4 17 6.23 17 9C17 11.76 14.76 14 12 14Z"
          stroke="#2D2D2D"
          strokeOpacity="1.000000"
          strokeWidth="1.500000"
          strokeLinejoin="round"
        />
        <path
          id="Vector"
          d="M12 17.25L14.63 23L17.19 20.12L21 19.89L18.21 14"
          stroke="#2D2D2D"
          strokeOpacity="1.000000"
          strokeWidth="1.500000"
          strokeLinejoin="round"
          strokeLinecap="round"
        />
        <path
          id="Vector"
          d="M5.65 14L3 19.87L6.76 20.1L9.34 23L12 17.2"
          stroke="#2D2D2D"
          strokeOpacity="1.000000"
          strokeWidth="1.500000"
          strokeLinejoin="round"
          strokeLinecap="round"
        />
      </g>
    </svg>
  ),
  rewards: (
    <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none">
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        id="Vector"
        d="M12 17C7.58 17 4 13.41 4 9C4 4.58 7.58 1 12 1C16.41 1 20 4.58 20 9C20 13.41 16.41 17 12 17Z"
        stroke="#FFFFFF"
        strokeOpacity="1.000000"
        strokeWidth="1.500000"
        strokeLinejoin="round"
      />
      <path
        id="Vector"
        d="M12 14C14.76 14 17 11.76 17 9C17 6.23 14.76 4 12 4C9.23 4 7 6.23 7 9C7 11.76 9.23 14 12 14Z"
        fill="#FFFFFF"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
      <path
        id="Vector"
        d="M12 14C9.23 14 7 11.76 7 9C7 6.23 9.23 4 12 4C14.76 4 17 6.23 17 9C17 11.76 14.76 14 12 14Z"
        stroke="#FFFFFF"
        strokeOpacity="1.000000"
        strokeWidth="1.500000"
        strokeLinejoin="round"
      />
      <path
        id="Vector"
        d="M12 17.25L14.63 23L17.19 20.12L21 19.89L18.21 14"
        fill="#FFFFFF"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
      <path
        id="Vector"
        d="M14.63 23L17.19 20.12L21 19.89L18.21 14C17.7 15.99 13.8 18 12 17.25L14.63 23Z"
        stroke="#FFFFFF"
        strokeOpacity="1.000000"
        strokeWidth="1.500000"
        strokeLinejoin="round"
      />
      <path
        id="Vector"
        d="M5.65 14L3 19.87L6.76 20.1L9.34 23L12 17.2"
        fill="#FFFFFF"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
      <path
        id="Vector"
        d="M3 19.87L6.76 20.1L9.34 23L12 17.2C8.4 18.21 6.27 15.49 5.65 14L3 19.87Z"
        stroke="#FFFFFF"
        strokeOpacity="1.000000"
        strokeWidth="1.500000"
        strokeLinejoin="round"
      />
    </svg>
  ),
  rocket_black: (
    <svg
      width="21.499390"
      height="21.498230"
      viewBox="0 0 21.4994 21.4982"
      fill="none"
    >
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        id="Vector"
        d="M21.16 0.84C21.09 0.59 20.9 0.4 20.65 0.33C20.65 0.33 14.26 -1.57 9.01 3.68C6.42 6.27 3.72 10.51 3.52 12.94C3.5 13.16 3.58 13.38 3.73 13.54L7.95 17.76C8.11 17.91 8.33 17.99 8.55 17.97C10.98 17.77 15.22 15.07 17.81 12.48C23.06 7.23 21.16 0.84 21.16 0.84ZM19.82 1.67C20.08 3.05 20.59 7.58 16.74 11.42C14.52 13.65 10.98 16.05 8.75 16.44L5.05 12.74C5.44 10.51 7.84 6.97 10.07 4.75C13.91 0.9 18.44 1.41 19.81 1.67L19.82 1.67Z"
        fill="#2D2D2D"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
      <path
        id="Vector"
        d="M16.75 4.74C15.49 3.48 13.43 3.48 12.17 4.74C10.91 6 10.91 8.05 12.17 9.32C13.43 10.58 15.49 10.58 16.75 9.32C18.01 8.05 18.01 6 16.75 4.74ZM15.69 5.8C16.37 6.48 16.37 7.58 15.69 8.26C15.01 8.93 13.91 8.93 13.23 8.26C12.55 7.58 12.55 6.48 13.23 5.8C13.91 5.12 15.01 5.12 15.69 5.8Z"
        fill="#2D2D2D"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
      <path
        id="Vector"
        d="M14.07 14.35C14.07 14.35 13.92 16 13.81 17.14C13.77 17.56 13.53 17.93 13.16 18.13L10.64 19.49L10.64 17.23C10.64 16.81 10.3 16.48 9.89 16.48C9.48 16.48 9.14 16.81 9.14 17.23L9.14 20.74C9.14 21.01 9.28 21.25 9.5 21.39C9.73 21.52 10.01 21.53 10.25 21.4C10.25 21.4 12.53 20.17 13.87 19.45C14.68 19.01 15.22 18.2 15.31 17.28C15.41 16.13 15.56 14.48 15.56 14.48C15.6 14.07 15.29 13.7 14.88 13.67C14.47 13.63 14.1 13.93 14.07 14.35Z"
        fill="#2D2D2D"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
      <path
        id="Vector"
        d="M7.01 5.93C7.01 5.93 5.35 6.08 4.21 6.18C3.29 6.27 2.47 6.81 2.04 7.62C1.32 8.96 0.09 11.24 0.09 11.24C-0.04 11.48 -0.03 11.76 0.1 11.99C0.24 12.21 0.48 12.35 0.75 12.35L4.26 12.35C4.68 12.35 5.01 12.01 5.01 11.6C5.01 11.19 4.68 10.85 4.26 10.85L2 10.85L3.36 8.33C3.56 7.96 3.93 7.72 4.35 7.68C5.49 7.57 7.14 7.42 7.14 7.42C7.56 7.39 7.86 7.02 7.82 6.61C7.79 6.2 7.42 5.89 7.01 5.93Z"
        fill="#2D2D2D"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
      <path
        id="Vector"
        d="M2.16 14.76L0.57 16.35C0.27 16.64 0.27 17.11 0.57 17.41C0.86 17.7 1.33 17.7 1.63 17.41L3.22 15.82C3.51 15.52 3.51 15.05 3.22 14.76C2.92 14.46 2.45 14.46 2.16 14.76Z"
        fill="#2D2D2D"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
      <path
        id="Vector"
        d="M3.91 16.51L0.92 19.51C0.62 19.8 0.62 20.28 0.92 20.57C1.21 20.86 1.69 20.86 1.98 20.57L4.97 17.57C5.27 17.28 5.27 16.81 4.97 16.51C4.68 16.22 4.21 16.22 3.91 16.51Z"
        fill="#2D2D2D"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
      <path
        id="Vector"
        d="M5.67 18.27L4.08 19.86C3.79 20.15 3.79 20.63 4.08 20.92C4.38 21.21 4.85 21.21 5.14 20.92L6.73 19.33C7.03 19.04 7.03 18.56 6.73 18.27C6.44 17.98 5.97 17.98 5.67 18.27Z"
        fill="#2D2D2D"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
    </svg>
  ),
  rocket: (
    <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none">
      <desc>Created with Pixso.</desc>
      <defs>
        <clipPath id="clip3_14">
          <rect
            id="Rocket"
            rx="-0.500000"
            width="23.000000"
            height="23.000000"
            transform="translate(0.500000 0.500000)"
            fill="white"
            fillOpacity="0"
          />
        </clipPath>
      </defs>
      <rect
        id="Rocket"
        rx="-0.500000"
        width="23.000000"
        height="23.000000"
        transform="translate(0.500000 0.500000)"
        fill="#FFFFFF"
        fillOpacity="0"
      />
      <g clipPath="url(#clip3_14)">
        <path
          id="Vector"
          d="M11.14 17.73C10.73 17.73 10.39 18.07 10.39 18.48L10.39 22C10.39 22.26 10.53 22.51 10.75 22.64C10.98 22.78 11.26 22.78 11.5 22.66C11.5 22.66 13.78 21.43 15.12 20.7C15.93 20.27 16.47 19.45 16.56 18.53C16.66 17.39 16.81 15.73 16.81 15.73C16.85 15.32 16.54 14.96 16.13 14.92C15 14.82 14.3 17.73 11.14 17.73Z"
          fill="#FFFFFF"
          fillOpacity="1.000000"
          fillRule="evenodd"
        />
        <path
          id="Vector"
          d="M8.26 7.18C8.26 7.18 6.6 7.33 5.46 7.44C4.54 7.52 3.72 8.06 3.29 8.87C2.57 10.21 1.34 12.5 1.34 12.5C1.21 12.73 1.22 13.01 1.35 13.24C1.49 13.46 1.73 13.6 2 13.6L5.51 13.6C5.93 13.6 6.26 13.27 6.26 12.85C6.26 11.64 8.5 9.5 9.07 7.86C9.04 7.45 8.67 7.14 8.26 7.18Z"
          fill="#FFFFFF"
          fillOpacity="1.000000"
          fillRule="evenodd"
        />
        <path
          id="Vector"
          d="M3.41 16.01L1.82 17.6C1.52 17.89 1.52 18.37 1.82 18.66C2.11 18.95 2.58 18.95 2.88 18.66L4.47 17.07C4.76 16.78 4.76 16.3 4.47 16.01C4.17 15.71 3.7 15.71 3.41 16.01Z"
          fill="#FFFFFF"
          fillOpacity="1.000000"
          fillRule="evenodd"
        />
        <path
          id="Vector"
          d="M5.16 17.77L2.17 20.76C1.87 21.06 1.87 21.53 2.17 21.82C2.46 22.12 2.94 22.12 3.23 21.82L6.22 18.83C6.52 18.54 6.52 18.06 6.22 17.77C5.93 17.47 5.46 17.47 5.16 17.77Z"
          fill="#FFFFFF"
          fillOpacity="1.000000"
          fillRule="evenodd"
        />
        <path
          id="Vector"
          d="M6.92 19.53L5.33 21.12C5.04 21.41 5.04 21.88 5.33 22.18C5.63 22.47 6.1 22.47 6.39 22.18L7.98 20.59C8.28 20.29 8.28 19.82 7.98 19.53C7.69 19.23 7.22 19.23 6.92 19.53Z"
          fill="#FFFFFF"
          fillOpacity="1.000000"
          fillRule="evenodd"
        />
        <path
          id="Subtract"
          d="M22.4144 2.09497C22.3424 1.84998 22.1514 1.65894 21.9064 1.58698C21.9064 1.58698 15.5184 -0.314026 10.2624 4.94092C7.67236 7.53094 4.97736 11.77 4.77036 14.2C4.75136 14.4199 4.83035 14.6379 4.98737 14.7939L9.20737 19.014C9.36336 19.171 9.58136 19.2499 9.80136 19.231C12.2314 19.0239 16.4704 16.3289 19.0604 13.739C24.3154 8.48297 22.4144 2.09497 22.4144 2.09497ZM13.4267 5.99609C14.6897 4.73309 16.7417 4.73309 18.0047 5.99609C19.2677 7.25909 19.2677 9.3111 18.0047 10.5741C16.7417 11.8371 14.6897 11.8371 13.4267 10.5741C12.1637 9.3111 12.1637 7.25909 13.4267 5.99609ZM16.9437 9.51306C17.6217 8.83508 17.6217 7.73511 16.9437 7.05707C16.2657 6.37909 15.1657 6.37909 14.4877 7.05707C13.8097 7.73511 13.8097 8.83508 14.4877 9.51306C15.1657 10.1911 16.2657 10.1911 16.9437 9.51306Z"
          clipRule="evenodd"
          fill="#FFFFFF"
          fillOpacity="1.000000"
          fillRule="evenodd"
        />
      </g>
    </svg>
  ),
  wallet_black: (
    <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none">
      <desc>Created with Pixso.</desc>
      <defs>
        <clipPath id="clip3_214">
          <rect
            id="Wallet"
            rx="-0.500000"
            width="23.000000"
            height="23.000000"
            transform="translate(0.500000 0.500000)"
            fill="white"
            fillOpacity="0"
          />
        </clipPath>
        <linearGradient
          x1="-8.000001"
          y1="12.000000"
          x2="34.000000"
          y2="12.000000"
          id="paint_linear_3_218_0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6DEFF1" />
          <stop offset="1.000000" stopColor="#F100E4" />
        </linearGradient>
        <linearGradient
          x1="-8.000001"
          y1="12.000000"
          x2="34.000000"
          y2="12.000000"
          id="paint_linear_3_218_1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6DEFF1" />
          <stop offset="1.000000" stopColor="#F100E4" />
        </linearGradient>
      </defs>
      <rect
        id="Wallet"
        rx="-0.500000"
        width="23.000000"
        height="23.000000"
        transform="translate(0.500000 0.500000)"
        fill="#FFFFFF"
        fillOpacity="0"
      />
      <g clipPath="url(#clip3_214)">
        <path
          id="wallet_stroke"
          d="M21.5 11.5L21.5 9C21.49 8.33 21.23 7.7 20.76 7.23C20.29 6.76 19.66 6.5 19 6.5L18 6.5L18 4C18 3.78 17.95 3.56 17.86 3.37C17.77 3.17 17.63 2.99 17.47 2.85C17.3 2.71 17.11 2.61 16.9 2.55C16.69 2.49 16.47 2.48 16.26 2.51L3.81 4.52C3.16 4.62 2.57 4.95 2.15 5.45C1.72 5.95 1.49 6.58 1.5 7.24L1.5 19C1.5 19.66 1.76 20.29 2.23 20.76C2.7 21.23 3.33 21.49 3.99 21.5L19 21.5C19.66 21.49 20.29 21.23 20.76 20.76C21.23 20.29 21.49 19.66 21.49 19L21.49 16.5C21.76 16.49 22.01 16.39 22.2 16.2C22.39 16.01 22.49 15.76 22.5 15.5L22.5 12.5C22.49 12.23 22.39 11.98 22.2 11.79C22.01 11.6 21.76 11.5 21.5 11.5ZM20.5 19C20.49 19.39 20.34 19.77 20.06 20.06C19.77 20.34 19.39 20.49 19 20.5L4 20.5C3.6 20.49 3.22 20.34 2.93 20.06C2.65 19.77 2.5 19.39 2.5 19L2.5 7.24C2.49 6.82 2.64 6.42 2.91 6.1C3.18 5.78 3.56 5.57 3.97 5.51L16.42 3.5C16.49 3.49 16.56 3.49 16.63 3.51C16.7 3.53 16.76 3.57 16.82 3.61C16.87 3.66 16.92 3.72 16.95 3.79C16.98 3.85 16.99 3.92 16.99 4L16.99 6.5L4.99 6.5C4.93 6.49 4.86 6.51 4.8 6.53C4.74 6.56 4.69 6.59 4.64 6.64C4.59 6.69 4.55 6.74 4.53 6.8C4.5 6.86 4.49 6.93 4.49 7C4.49 7.06 4.5 7.13 4.53 7.19C4.55 7.25 4.59 7.3 4.64 7.35C4.69 7.4 4.74 7.43 4.8 7.46C4.86 7.48 4.93 7.5 4.99 7.49L19 7.49C19.39 7.5 19.77 7.65 20.06 7.93C20.34 8.22 20.49 8.6 20.5 8.99L20.5 11.49L16 11.49C15.33 11.49 14.7 11.76 14.23 12.23C13.76 12.7 13.5 13.33 13.5 13.99C13.5 14.66 13.76 15.29 14.23 15.76C14.7 16.23 15.33 16.49 16 16.49L20.5 16.49L20.5 19ZM21.5 15.5L16 15.5C15.6 15.5 15.22 15.34 14.93 15.06C14.65 14.77 14.5 14.39 14.5 14C14.5 13.6 14.65 13.22 14.93 12.93C15.22 12.65 15.6 12.5 16 12.5L21.5 12.5L21.5 15.5Z"
          fill="#2D2D2D"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="wallet_stroke"
          d="M21.5 9C21.49 8.33 21.23 7.7 20.76 7.23C20.29 6.76 19.66 6.5 19 6.5L18 6.5L18 4C18 3.78 17.95 3.56 17.86 3.37C17.77 3.17 17.63 2.99 17.47 2.85C17.3 2.71 17.11 2.61 16.9 2.55C16.69 2.49 16.47 2.48 16.26 2.51L3.81 4.52C3.16 4.62 2.57 4.95 2.15 5.45C1.72 5.95 1.49 6.58 1.5 7.24L1.5 19C1.5 19.66 1.76 20.29 2.23 20.76C2.7 21.23 3.33 21.49 3.99 21.5L19 21.5C19.66 21.49 20.29 21.23 20.76 20.76C21.23 20.29 21.49 19.66 21.49 19L21.49 16.5C21.76 16.49 22.01 16.39 22.2 16.2C22.39 16.01 22.49 15.76 22.5 15.5L22.5 12.5C22.49 12.23 22.39 11.98 22.2 11.79C22.01 11.6 21.76 11.5 21.5 11.5L21.5 9ZM20.06 20.06C19.77 20.34 19.39 20.49 19 20.5L4 20.5C3.6 20.49 3.22 20.34 2.93 20.06C2.65 19.77 2.5 19.39 2.5 19L2.5 7.24C2.49 6.82 2.64 6.42 2.91 6.1C3.18 5.78 3.56 5.57 3.97 5.51L16.42 3.5C16.49 3.49 16.56 3.49 16.63 3.51C16.7 3.53 16.76 3.57 16.82 3.61C16.87 3.66 16.92 3.72 16.95 3.79C16.98 3.85 16.99 3.92 16.99 4L16.99 6.5L4.99 6.5C4.93 6.49 4.86 6.51 4.8 6.53C4.74 6.56 4.69 6.59 4.64 6.64C4.59 6.69 4.55 6.74 4.53 6.8C4.5 6.86 4.49 6.93 4.49 7C4.49 7.06 4.5 7.13 4.53 7.19C4.55 7.25 4.59 7.3 4.64 7.35C4.69 7.4 4.74 7.43 4.8 7.46C4.86 7.48 4.93 7.5 4.99 7.49L19 7.49C19.39 7.5 19.77 7.65 20.06 7.93C20.34 8.22 20.49 8.6 20.5 8.99L20.5 11.49L16 11.49C15.33 11.49 14.7 11.76 14.23 12.23C13.76 12.7 13.5 13.33 13.5 13.99C13.5 14.66 13.76 15.29 14.23 15.76C14.7 16.23 15.33 16.49 16 16.49L20.5 16.49L20.5 19C20.49 19.39 20.34 19.77 20.06 20.06ZM16 15.5C15.6 15.5 15.22 15.34 14.93 15.06C14.65 14.77 14.5 14.39 14.5 14C14.5 13.6 14.65 13.22 14.93 12.93C15.22 12.65 15.6 12.5 16 12.5L21.5 12.5L21.5 15.5L16 15.5Z"
          stroke="#2D2D2D"
          strokeOpacity="1.000000"
          strokeWidth="0.500000"
        />
      </g>
    </svg>
  ),
  wallet: (
    <svg
      width="21.500000"
      height="19.500244"
      viewBox="0 0 21.5 19.5002"
      fill="none"
    >
      <desc>Created with Pixso.</desc>
      <defs>
        <linearGradient
          x1="-9.250001"
          y1="9.750244"
          x2="32.750000"
          y2="9.750244"
          id="paint_linear_3_196_0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6DEFF1" />
          <stop offset="1.000000" stopColor="#F100E4" />
        </linearGradient>
        <linearGradient
          x1="-9.250001"
          y1="9.750244"
          x2="32.750000"
          y2="9.750244"
          id="paint_linear_3_196_1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6DEFF1" />
          <stop offset="1.000000" stopColor="#F100E4" />
        </linearGradient>
      </defs>
      <path
        id="wallet"
        d="M20.25 9.25L20.25 6.75C20.24 6.08 19.98 5.45 19.51 4.98C19.04 4.51 18.41 4.25 17.75 4.25L16.75 4.25L16.75 1.75C16.75 1.53 16.7 1.31 16.61 1.12C16.52 0.92 16.38 0.74 16.22 0.6C16.05 0.46 15.86 0.36 15.65 0.3C15.44 0.24 15.22 0.23 15.01 0.26L2.56 2.27C1.91 2.37 1.32 2.7 0.9 3.2C0.47 3.7 0.24 4.33 0.25 4.99L0.25 16.75C0.25 17.41 0.51 18.04 0.98 18.51C1.45 18.98 2.08 19.24 2.75 19.25L17.75 19.25C18.41 19.24 19.04 18.98 19.51 18.51C19.98 18.04 20.24 17.41 20.25 16.75L20.25 14.25C20.51 14.25 20.76 14.14 20.95 13.95C21.14 13.76 21.24 13.51 21.25 13.25L21.25 10.25C21.24 9.98 21.14 9.73 20.95 9.54C20.76 9.35 20.51 9.25 20.25 9.25ZM20.25 13.25L14.75 13.25C14.35 13.25 13.97 13.09 13.68 12.81C13.4 12.52 13.25 12.14 13.25 11.75C13.25 11.35 13.4 10.97 13.68 10.68C13.97 10.4 14.35 10.25 14.75 10.25L20.25 10.25L20.25 13.25Z"
        fill="#FFFFFF"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
      <path
        id="wallet"
        d="M20.25 6.75C20.24 6.08 19.98 5.45 19.51 4.98C19.04 4.51 18.41 4.25 17.75 4.25L16.75 4.25L16.75 1.75C16.75 1.53 16.7 1.31 16.61 1.12C16.52 0.92 16.38 0.74 16.22 0.6C16.05 0.46 15.86 0.36 15.65 0.3C15.44 0.24 15.22 0.23 15.01 0.26L2.56 2.27C1.91 2.37 1.32 2.7 0.9 3.2C0.47 3.7 0.24 4.33 0.25 4.99L0.25 16.75C0.25 17.41 0.51 18.04 0.98 18.51C1.45 18.98 2.08 19.24 2.75 19.25L17.75 19.25C18.41 19.24 19.04 18.98 19.51 18.51C19.98 18.04 20.24 17.41 20.25 16.75L20.25 14.25C20.51 14.25 20.76 14.14 20.95 13.95C21.14 13.76 21.24 13.51 21.25 13.25L21.25 10.25C21.24 9.98 21.14 9.73 20.95 9.54C20.76 9.35 20.51 9.25 20.25 9.25L20.25 6.75ZM14.75 13.25C14.35 13.25 13.97 13.09 13.68 12.81C13.4 12.52 13.25 12.14 13.25 11.75C13.25 11.35 13.4 10.97 13.68 10.68C13.97 10.4 14.35 10.25 14.75 10.25L20.25 10.25L20.25 13.25L14.75 13.25Z"
        stroke="#FFFFFF"
        strokeOpacity="1.000000"
        strokeWidth="0.500000"
      />
      <path
        id="wallet"
        d="M20.25 6.75L20.25 8.75L19.75 8.75L19.25 8.75L19.25 6.75C19.24 6.35 19.09 5.97 18.81 5.69C18.52 5.4 18.14 5.25 17.75 5.25L3.75 5.25C3.68 5.25 3.61 5.23 3.55 5.21C3.49 5.18 3.44 5.15 3.39 5.1C3.34 5.05 3.3 5 3.28 4.94C3.25 4.88 3.24 4.81 3.24 4.75C3.24 4.68 3.25 4.61 3.28 4.55C3.3 4.49 3.34 4.44 3.39 4.39C3.44 4.34 3.49 4.31 3.55 4.28C3.61 4.26 3.68 4.24 3.75 4.25L15.74 4.25L16.75 4.25L17.75 4.25C18.41 4.25 19.04 4.51 19.51 4.98C19.98 5.45 20.24 6.08 20.25 6.75Z"
        fill="url(#paint_linear_3_196_0)"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
      <path
        id="wallet"
        d="M20.25 6.75C20.24 6.08 19.98 5.45 19.51 4.98C19.04 4.51 18.41 4.25 17.75 4.25L16.75 4.25L15.74 4.25L3.75 4.25C3.68 4.24 3.61 4.26 3.55 4.28C3.49 4.31 3.44 4.34 3.39 4.39C3.34 4.44 3.3 4.49 3.28 4.55C3.25 4.61 3.24 4.68 3.24 4.75C3.24 4.81 3.25 4.88 3.28 4.94C3.3 5 3.34 5.05 3.39 5.1C3.44 5.15 3.49 5.18 3.55 5.21C3.61 5.23 3.68 5.25 3.75 5.25L17.75 5.25C18.14 5.25 18.52 5.4 18.81 5.69C19.09 5.97 19.24 6.35 19.25 6.75L19.25 8.75L19.75 8.75L20.25 8.75L20.25 6.75Z"
        stroke="url(#paint_linear_3_196_1)"
        strokeOpacity="1.000000"
        strokeWidth="0.500000"
      />
    </svg>
  ),
};
