// import WalletBalance from "components/WalletConnect/WalletBalance/WalletBalance";
import WalletConnect from "components/WalletConnect/WalletConnect";

export default function Wallet() {
  return (
    <>
      <WalletConnect />

      {/* <WalletBalance /> */}
    </>
  );
}
