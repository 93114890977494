import React, { useState, useEffect } from "react";
import s from "./CountDownTimer.module.scss";

type CountdownTimerProps = {
  className?: string;
};

const CountdownTimer: React.FC<CountdownTimerProps> = ({ className }) => {
  const [timeLeft, setTimeLeft] = useState<number>(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const nextMonth: any = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        1
      );
      const distance = nextMonth - now;
      setTimeLeft(distance);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
  return (
    <div className={className}>
      <p className={s.title}>До конца сезона осталось:</p>
      <div className={s.timer}>
        {days} дней {hours} часов {minutes} минут {seconds} секунд
      </div>
    </div>
  );
};

export default CountdownTimer;
