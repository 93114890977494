import { NavLink, useLocation } from "react-router-dom";
import s from "./Link.module.scss";
import { ReactNode, useContext } from "react";
import LeagueContext from "context/LeagueContext/LeagueContext";
import path from "path";

export default function Link({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) {
  const { pathname } = useLocation();
  // alert(JSON.stringify(href));
  // alert(JSON.stringify(pathname));
  return (
    <NavLink
      // className={({ isActive }) => {
      //   return [isActive ? s.active : "", s.link].join(" ");
      // }}

      className={`${s.link} ${href === pathname ? s.active : ""}`}
      to={href}
    >
      {children}
    </NavLink>
  );
}
