import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Navigation from "./components/Navigation/Navigation";
import { lazy, Suspense, useEffect, useState } from "react";
import Leagues from "pages/Leagues/Leagues";
import Rewards from "pages/Rewards/Rewards";
import Tapper from "components/Tapper/Tapper";
import Wallet from "pages/Wallet/Wallet";
import { LeagueContext } from "context/LeagueContext/LeagueContext";
import Loading from "components/common/Loading/Loading";
import { leaguesApi, LeagueType } from "api/leagues";
const Boost = lazy(() => import("pages/Boost/Boost"));

// const client = new ApolloClient({
//   uri: "https://api.clicktothesky.com/",
//   cache: new InMemoryCache(),
// });

// const tg = window.Telegram.WebApp;
// alert(JSON.stringify(tg?.initDataUnsafe?.user?.first_name));

function App() {
  const [leagues, setLeagues] = useState<LeagueType[] | null>(null); //type
  const [activeLeague, setActiveLeague] = useState<LeagueType | null>(null); //type

  useEffect(() => {
    async function getLeagues() {
      try {
        const data = await leaguesApi.getLeagues();
        setLeagues(data);

        const activeLeague =
          data.find((league: LeagueType) => league.id === 1) || null;
        setActiveLeague(activeLeague);
      } catch (e) {
        console.log(e);
      }
    }
    getLeagues();
  }, []);

  return (
    <div className="App">
      <LeagueContext.Provider value={[activeLeague, setActiveLeague]}>
        <Routes>
          <Route path="/" element={<Navigate to="/tapper" replace />} />
          <Route
            path="/boost"
            element={
              <Suspense fallback={<Loading />}>
                <Boost league={activeLeague} />{" "}
              </Suspense>
            }
          />
          <Route
            path="/leagues"
            element={
              <Suspense fallback={<Loading />}>
                <Leagues leagues={leagues} />
              </Suspense>
            }
          />
          <Route
            path="/rewards"
            element={
              <Suspense fallback={<Loading />}>
                <Rewards league={activeLeague} />
              </Suspense>
            }
          />
          <Route
            path="/wallet"
            element={
              <Suspense fallback={<Loading />}>
                <Wallet />
              </Suspense>
            }
          />
          <Route
            path="/tapper"
            element={
              <Suspense fallback={<Loading />}>
                <Tapper league={activeLeague} />
              </Suspense>
            }
          />
        </Routes>
      </LeagueContext.Provider>
      <Navigation />
    </div>
  );
}

export default App;
