import { LeagueType } from "api/leagues";
import LeaguesPage from "components/LeaguesPage/LeaguesPage";

export default function Leagues({ leagues }: { leagues: LeagueType[] | null }) {
  return (
    <>
      <LeaguesPage leagues={leagues} />
    </>
  );
}
