import React, { useContext, useEffect, useState } from "react";
import Reward from "./Reward/Reward";
import s from "./RewardsPage.module.scss";
import LeagueContext from "context/LeagueContext/LeagueContext";
import { LEAGUES } from "constants/contstans";
import { LeagueType } from "api/leagues";
export default function RewardsPage({ league }: { league: LeagueType | null }) {
  const [rewards, setRewards] = useState<number[]>([]);

  // const [activeLeague] = useContext(LeagueContext);

  // const quickClickRewards = [
  //   5000, 2000, 1500, 500, 250, 200, 200, 150, 100, 100,
  // ];
  // const nenoClickRewards = [1000, 500, 300, 200, 100, 100, 100, 100, 100, 100];
  // const chromazzCoinRewards = [100, 500, 300, 200, 100];
  // const lapsOfLuxRewards = [1000, 200, 100, 100, 100, 100, 100, 100, 100, 100];
  // const broScamsRewards = [1000, 500, 250, 250, 250, 250, 250, 250, 250, 250];

  // useEffect(() => {
  //   if (league) {
  //     if (league === LEAGUES.QUICK_CLICK) {
  //       setRewards(quickClickRewards);
  //     } else if (league === LEAGUES.NENO_TOKEN) {
  //       setRewards(nenoClickRewards);
  //     } else if (league === LEAGUES.CHROMAZZ_COIN) {
  //       setRewards(chromazzCoinRewards);
  //     } else if (league === LEAGUES.LAPSOFLUX) {
  //       setRewards(lapsOfLuxRewards);
  //     } else if (league === LEAGUES.BRO_SCAMS) {
  //       setRewards(broScamsRewards);
  //     }
  //   }
  // }, []);

  return (
    <section className={s.section}>
      <div className="container">
        <div className={s.container}>
          <div className={s.image}>
            <img src={league?.background || ""} alt={league?.name} />
          </div>

          <h2 className={s.title}>{league?.name} league</h2>
        </div>

        {league?.seasons[league?.seasons.length - 1]?.prizes?.map(
          (r, index) => (
            <React.Fragment key={index}>
              <Reward place={index + 1} prize={r} />
            </React.Fragment>
          )
        )}
      </div>
    </section>
  );
}
