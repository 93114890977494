import CountdownTimer from "components/CountDownTimer/CountDownTimer";
import s from "./Tapper.module.scss";
import { MouseEvent, useEffect, useState } from "react";
import Energy from "./Energy/Energy";
import { usersApi } from "api/users";
import { leaguesApi, LeagueType } from "api/leagues";
import Popup from "components/common/Popup/Popup";

export default function Tapper({ league }: { league: LeagueType | null }) {
  // const [energyCount, setEnergyCount] = useState(2000);
  const [clicks, setClicks] = useState<{ id: number; x: number; y: number }[]>(
    []
  );
  const [isShowPopup, setIsShowPopup] = useState(false);
  // const [scores, setScores] = useState(0);
  const pointsToAdd = 1;
  const leagueId = league?.id || 1;
  const seasonId = league?.seasons[league.seasons.length - 1].id || 1;
  const [localScores, setLocalScores] = useState(10);
  const [localEnergy, setLocalEnergy] = useState(0);

  const tg = window.Telegram.WebApp;
  const userId = tg?.initDataUnsafe?.user?.id;

  useEffect(() => {
    async function isFirstPlayOnLeague() {
      try {
        const aboutLeague = await usersApi.isFirstPlayOnLeague(
          userId,
          leagueId
        );

        if (aboutLeague.length === 0) {
          await leaguesApi.joinTheLeague(userId, leagueId || 1, seasonId);
          setIsShowPopup(true);
        } else {
          setIsShowPopup(false);
        }

        const energy = await usersApi.getEnergy(userId, leagueId);
        // alert(energy);

        // Обновляем состояния после получения всех данных
        setLocalScores(aboutLeague[0]?.score || 0);
        setLocalEnergy(energy);
      } catch (e) {
        console.log(e);
      }
    }

    isFirstPlayOnLeague();
  }, []);

  const handleCardClick = async (e: MouseEvent<HTMLDivElement>) => {
    // const touch = e.changedTouches[0];
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left - rect.width / 2;
    const y = e.clientY - rect.top - rect.height / 2;

    card.style.transform = `perspective(1000px) rotateX(${
      -y / 10
    }deg) rotateY(${x / 10}deg)`;

    setTimeout(() => {
      card.style.transform = "";
    }, 100);
    setClicks([...clicks, { id: Date.now(), x: e.pageX, y: e.pageY }]);
    if (localEnergy) {
      setLocalScores((prev) => (prev += 1));
      setLocalEnergy((prev) => (prev -= 1));
      if ((localScores + 1) % 5 === 0) {
        await usersApi.updateEnergy(userId, leagueId, seasonId);
      }
    }
  };
  const handleAnimationEnd = (id: number) => {
    setClicks((prevClicks) => prevClicks.filter((click) => click.id !== id));

    if (navigator.vibrate) {
      // vibration API supported
      navigator.vibrate(1000);
    }
  };

  const [is, setIs] = useState(true);

  if (isShowPopup) {
    return <Popup closePopup={() => setIsShowPopup(false)} />;
  }

  return (
    <section className={s.section}>
      <div className="container">
        <div className={s.container}>
          <div className={s.flexGrowContainer}>
            <CountdownTimer className={s.timer} />
            <div className={s.tapsCount}>Scores: {localScores}</div>
          </div>
          <div
            onClick={(e) => handleCardClick(e)}
            // onTouchStart={(e) => handleCardClick(e)}
            className={s.buttonContainer}
            // onTouchStart={() => setIsPressed(true)}
            // onTouchEnd={() => setIsPressed(false)}
          >
            <img src="/leagues/quick-click-tap.png" alt="" />
          </div>
          <Energy
            energy={localEnergy}
            progress={((localEnergy / 2000) * 100).toString()}
          />
        </div>
      </div>
      {clicks.map((click) => (
        <div
          key={click.id}
          className={s.animation}
          style={{
            top: `${click.y - 42}px`,
            left: `${click.x - 28}px`,
          }}
          onAnimationEnd={() => handleAnimationEnd(click.id)}
        >
          +{pointsToAdd}
        </div>
      ))}
    </section>
  );
}
