import s from "./WalletConnect.module.scss";
import React, { useEffect, useState } from "react";
import {
  TonConnectButton,
  useIsConnectionRestored,
  useTonAddress,
  useTonWallet,
} from "@tonconnect/ui-react";
// import axios from "axios";
import { formatAddress } from "utils/formatAddress";
import { usersApi } from "api/users";
import Description from "./Descriotion/Description";

const WalletConnect: React.FC = () => {
  // const [balance, setBalance] = useState<number | null>(null); // Типизируем состояние баланса
  // const [error, setError] = useState<string | null>(null); // Типизируем состояние ошибки
  const address = useTonAddress(false); // Получаем сырой адрес
  const connectionRestored = useIsConnectionRestored();
  const [commonScores, setCommonScores] = useState(0);
  const [refsCount, setRefsCount] = useState(0);
  const wallet = useTonWallet();

  const tg = window.Telegram.WebApp;
  const userId = tg?.initDataUnsafe?.user?.id;
  // Получение баланса после подключения

  const description = [
    {
      title: "WHATS QUICK CLICK?",
      text: "It’s a play to earn game. Tap the screen to earn Quick points. User with the highest points wins cash prize end of every month!",
    },
    {
      title: "HOW TO EARN MORE?",
      text: "Quick Leagues! Join a different league to win their additional cash prize pools. Each league has its own boost tasks. Make sure to check every leagues boost.",
    },
    {
      title: "HOW TO EARN MORE?",
      text: "If you want to promote your project, business/community. You can contact us. We will create you a league were users can complete tasks to earn rewards. You decide how high the rewards and prizes are for completed tasks!",
    },
    {
      title: "EARN MORE!? $500 AFFILIATE COMMISSION!",
      text: "For every new project referred to Quick Click that starts a league you will earn $500 Remember admins will never message you first. Be careful of others posing as Quick Click Admins.",
    },
  ];

  // const getWalletBalance = async () => {
  //   try {
  //     if (wallet) {
  //       const provider = wallet.provider;
  //       const walletAddress = wallet.account.address;
  //       console.log(walletAddress);
  //       const balance = await provider.getBalance(walletAddress);
  //       console.log(balance / 1e9); // Переводим нанотоны в тоны
  //     }
  //   } catch (error) {
  //     console.error("Ошибка получения баланса:", error);
  //   }
  // };

  useEffect(() => {
    async function getCommonScores() {
      try {
        const scores = await usersApi.getCommonScores(userId);
        const refsCount = await usersApi.getRefs(userId);
        if (scores) {
          setCommonScores(scores);
        }
        if (refsCount) {
          setRefsCount(refsCount);
        }
      } catch (e) {
        console.log(e);
      }
    }
    getCommonScores();
  }, []);
  // useEffect(() => {
  //   const fetchBalance = async () => {
  //     if (address) {
  //       try {
  //         const response = await axios.get(
  //           `https://tonapi.io/v1/accounts/${address}/balance`
  //         );
  //         setBalance(response.data.balance);
  //         console.log(response);
  //       } catch (error) {
  //         console.error("Ошибка при получении баланса:", error);
  //         setError(`${error}`);
  //       }
  //     }
  //   };

  //   fetchBalance();
  // }, [address]);

  if (!connectionRestored) {
    return <div>Please wait...</div>;
  }

  return (
    <section className={s.container}>
      <div className="container">
        <div className={s.connectButton}>
          {address && (
            <>
              <h2 className={s.address}>
                Подключен кошелек: {formatAddress(address)}
              </h2>
              {/* {balance !== null ? (
                <h3>Баланс: {balance} TON</h3>
              ) : (
                <p>Загрузка баланса...</p>
              )} */}
            </>
          )}
          <TonConnectButton />
        </div>

        <div className={s.statisticsContainer}>
          <h3 className={s.statistics}>Statistics</h3>
          <div className={s.scoresCount}>
            Общее количество натапанного: <span>{commonScores || 0}</span>
          </div>
          <div className={s.scoresCount}>
            Количество приглашенных друзей: <span>{refsCount || 0}</span>
          </div>
        </div>
        <div className={s.description}>
          {description.map((d, index) => (
            <React.Fragment key={index + d.title}>
              <Description text={d.text} title={d.title} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WalletConnect;
