import { useEffect, useState } from "react";
import League from "./League/League";
import s from "./Leagues.module.scss";
import { LeagueType } from "api/leagues";
import { ScoresType, usersApi } from "api/users";

export default function LeaguesPage({
  leagues,
}: {
  leagues: LeagueType[] | null;
}) {
  const tg = window.Telegram.WebApp;
  const userId = tg?.initDataUnsafe?.user?.id;
  const [scores, setScores] = useState<ScoresType[]>([]);

  useEffect(() => {
    async function getInfo() {
      const res = await usersApi.getScores(userId);
      if (res) {
        setScores(res);
      }
    }
    getInfo();
  }, []);
  return (
    <section className={s.section}>
      <div className="container">
        <h2 className={s.title}>Trending now</h2>
        {leagues?.map((l) => {
          const score = scores.find((sc) => sc.league_id === l.id);
          return (
            <div key={l.id} className={s.leaguesContainer}>
              <League
                leagueId={l.id}
                league={l.name}
                href={l}
                image={l.background}
                season={l.seasons[l.seasons.length - 1]}
                scores={score}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
}
