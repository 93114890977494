import { LeagueType } from "api/leagues";
import RewardsPage from "components/RewardsPage/RewardsPage";

export default function Rewards({ league }: { league: LeagueType | null }) {
  return (
    <>
      <RewardsPage league={league} />
    </>
  );
}
