import { instance } from "api";
import { AxiosResponse } from "axios";

export const leaguesApi = {
  async getLeagues(): Promise<LeagueType[]> {
    const response: AxiosResponse<GetLeaguesResponse> =
      await instance.get<GetLeaguesResponse>("leagues");
    return response.data.result;
  },
  async getActions(leagueId: number): Promise<ActionType[]> {
    const response: AxiosResponse<GetActionsResponse> =
      await instance.get<GetActionsResponse>("actions?league_id=" + leagueId);
    return response.data.result;
  },
  async joinTheLeague(userId: number, leagueId: number, seasonId: number) {
    const response = await instance.post("users/league", {
      user_id: userId,
      league_id: leagueId,
      league_season_id: seasonId,
    });
    return response.data.result;
  },
};

//Leagues types
type GetLeaguesResponse = {
  result: LeagueType[];
};

export type Season = {
  id: number;
  end_time: string;
  league_id: number;
  name: string;
  prizes: number[];
};

export type LeagueType = {
  id: number;
  background: null | string;
  list_cover: null | string;
  tap_image: null | string;
  seasons: Season[];
  name: string;
};
//----------------------------

// actions types

type GetActionsResponse = {
  result: ActionType[];
};

export type ActionType = {
  id: number;
  league_id: number;
  link: string;
  name: string;
  reward: string;
  code: string;
};

// getLeagues;
// `response/data/result/{
// created_at: null
// id:1
// name:"Test"
// seasons: {
//     created_at: null
//     end_time:"2024-09-23 21:44:20"
//     id: 1
//     league_id: 1
//     name: "Season 1"
//     prizes: [1, 2, 3, 4]
//     updated_at: null
// }
// updated_at: null
// }[]`;

// getActions
// response/data/result {}
// created_at: null;
// id: 1;
// league_id: 1;
// link: "t.me/wefewfw";
// name: "Join telegram";
// reward: "1000";
// updated_at: null;
